import * as React from 'react';
import { Box } from '@mui/material';

// Import your PNG image
import MyIcon from './file.png'; 

export default function SitemarkIcon() {
  return (
    <Box sx={{ mr: 2 }}>
      <img 
        src={MyIcon} 
        alt="Sitemark Icon" 
        style={{ width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '100%' }} 
      />
    </Box>
  );
}
