import * as React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';



import { ReactComponent as Logo } from '../logo.svg';



export default function Content() {
  return (
    <Stack
      sx={{ flexDirection: 'column', alignSelf: 'center', gap: 1, maxWidth: 550 }}
    >
      <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
      <Logo style={{ width: 'auto', height: 500 }} />
      </Box>
      
    </Stack>
  );
}
