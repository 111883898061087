import React, { useState } from 'react';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Blog from './blog/Blog';

import Login from './sign-in-side/SignInSide';
import ProtectedRoute from './Wrappers/ProtectedRoute';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false); 

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <Routes>
            
            <Route path="/" element={<Login onLogin={() => setIsAuthenticated(true)} />} />

            
            <Route
              path="/*"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <Blog />
                </ProtectedRoute>
              }
            />
          </Routes>
        </header>
      </div>
    </Router>
  );
}

export default App;
